import 'ag-grid-enterprise'
import MtApi from '@/agGridV2/helpers/mt-api.helper'

// getTrashIcon().then(elem => (trashIcon = elem))
export function getContextMenuItems(params) {
	const { api, node } = params
	const id = node?.id ?? undefined
	const project =
		node && node.data.project
			? `${node.data.project.abbr}-${node.data.project.type}`
			: undefined
	const selectedRows = api.getSelectedRows()
	const result = [
		'copy',
		'paste',
		'separator',
		{
			name: 'Delete',
			disabled: !node,
			action: async () => {
				const index = node.rowIndex !== null ? node.rowIndex + 1 : 0
				const isAllowed = confirm(
					`Are you sure you want to delete the ${
						project ?? index
					} project entry?`
				)
				if (isAllowed) {
          MtApi.removeContentRecord(id)
            .then(res => api.applyTransaction({ remove: [{ _id: id }] }))
						.catch(err => alert(err))
				} else {
					params.node.setSelected(false)
				}
			},
			// icon: ,
		},
		{
			name: 'Delete All Selected',
			disabled: selectedRows.length === 0,
			action: async () => {
				const isAllowed = confirm(
					`Are you sure you want to delete ${selectedRows.length} records?`
				)
				if (isAllowed) {
					const ids = []
					selectedRows.forEach(row => ids.push(row['_id']))
          MtApi.removeContentRecords(ids)
						.then(res => api.applyTransaction({ remove: selectedRows }))
						.catch(err => alert(err))
				}
			},
			// icon: trashIcon.cloneNode(true),
		},
	]

	return result
}

// async function getTrashIcon() {
// 	const trashIcon = document.createElement('div')
// 	trashIcon.innerHTML = await (await fetch(trashIconURL)).text()
// 	trashIcon.className = 'context-menu__trash-icon'
// 	return trashIcon
// }
