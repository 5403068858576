<template>
  <div class="projects-page">
    <tool-navigation/>
    <div class="agGrid-page container">
      <base-header>
        // TODO: project selector
        
      </base-header>

      <div class="ag-grid-container">
        <div class="ag-table">
          <ag-grid-vue
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
            headerHeight="32"
            rowHeight="32"
            :gridOptions="gridOptions"
            :rowSelection="rowSelection"
            :rowData="rowData"
            :columnDefs="columnDefs"
            :statusBar="statusBar"
            :getContextMenuItems="getContextMenuItems"
            :stopEditingWhenCellsLoseFocus="true"
            :cellSelection="true"
            @grid-ready="onGridReady"
            @filter-changed="stateEvents"
            @column-resized="stateEvents"
            @sort-changed="stateEvents"
            @column-visible="stateEvents"
            @column-pivot-changed="stateEvents"
            @column-row-group-changed="stateEvents"
            @column-value-changed="stateEvents"
            @column-moved="stateEvents"
            @column-pinned="stateEvents"
          >
          </ag-grid-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Init components
import 'ag-grid-enterprise/styles/ag-grid.css'
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { mapGetters, mapMutations } from 'vuex'
// Custom Cell Renderer
import ProfitRenderer from '@/agGridV2/renderers/profit.renderer.vue'

import ToolNavigation from '@/components/ToolNavigation.vue';
import { getColumnDefs } from './utils/getColumnDefs';
import { getContextMenuItems } from './utils/getContextMenuItems'
import AddRowStatusBar from './components/AddRowStatusBar.vue'

export default {
  components: {
    BaseHeader,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    ProfitRenderer,
    ToolNavigation,
    // eslint-disable-next-line vue/no-unused-components
    AddRowStatusBar
  },
  data() {
    return {
      rowData: null,
      columnDefs: null,
      gridApi: null,
      rowSelection: 'multiple',
      gridOptions: {
        getRowId: (params) => {
          return params.data['_id']
        },
		    // cellSelection: true,
        onCellValueChanged: this.onCellValueChanged,
        processCellForClipboard: (params) => {
          let value;
          if (!params.value) return;
          if (typeof params.value !== 'string') {
            value = JSON.stringify(params.value);
          } else value = params.value;
          return value;
        },
      },
      records: null,
      projects: null,
      editors: null,
      selectors: null,
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent' },
          { statusPanel: 'AddRowStatusBar', align: 'left' }
        ]
      },
      filters: ['SEO', 'PPC', 'DEV', 'MNT', 'DEV-INT', 'PPC-INT', 'OTH-INT', 'SEO-INT'],
      selectedFilters: ['SEO', 'PPC', 'DEV', 'MNT']
    }
  },
  methods: {
    ...mapMutations(['setViewLoader']),
    ...mapGetters(['getPlatforms']),
    onGridReady(params) {
      params.api.sizeColumnsToFit();
      this.gridApi = params.api;
    },
    updateRowData() {
      this.rowData = Object.freeze(
        this.copyRowData(this.rowData)
      );
    },
    setState() {
      const state = this.$store.getters.getAgGridState(this.$route.name)
      if (state) {
        this.gridApi.applyColumnState({ state: JSON.parse(state), applyOrder: true })
      }
      const stateFilter = this.$store.getters.getAgGridFilterChanged(this.$route.name)
      if (stateFilter) {
        this.gridApi.setFilterModel(JSON.parse(stateFilter))
      }
    },
    stateEvents(event) {
      if (event.type === 'filterChanged') {
        this.$store.dispatch('setAgGridFilterChanged', { page: this.$route.name, query: JSON.stringify(this.gridApi.getFilterModel()) })
      } else {
        this.$store.dispatch('setAgGridState', { page: this.$route.name, query: JSON.stringify(this.gridApi.getColumnState()) })
      }
    },
    copyRowData(data) {
      return data.map(datum => {
          return {
              ...datum
          }
      })
    },
    getContextMenuItems: getContextMenuItems,
    generateColumnDefs: getColumnDefs,
    applyTypeFilter() {
      const selectedValues = Array.from(document.querySelectorAll('#filterCheckboxes input:checked')).map(checkbox => checkbox.value);
      this.gridApi.getColumnFilterInstance('type').then(filterInstance => {
        if (filterInstance) {
            filterInstance.setModel({ values: selectedValues });
            this.gridApi.onFilterChanged();
        }
      });
    },
    async onCellValueChanged(event) {
      const { oldValue, newValue, colDef, node } = event;
      const { field } = colDef;
      const { id } = node;
      if (!field || !id) return;
      let data = { [field]: newValue };
      if (field === 'project') {
        data['projectManager'] = node.data.projectManager;
      }
      MtApi.updateContentRecord(id, data)
        .then(res => console.log(res))
        .catch(err => {
          this.gridApi.refreshCells({
            rowNodes: [node],
            columns: [field]
          })
          return console.error(err)
        })
    },
  },
  computed: {
    platforms() {
      return this.getPlatforms()
    }
  },
  beforeMount() {
    this.$emitter.on('header_search_addon_changed', () => {
      this.gridApi.setGridOption("quickFilterText", this.$store.getters.getSearchQuery(this.$route.name));
    });
    this.$emitter.on('header_reset_state', () => {
      this.gridApi.resetColumnState()
      const timeout = setTimeout(() => {
        this.$store.dispatch('setAgGridState', { page: this.$route.name, query: '' })
        clearTimeout(timeout)
      }, 10)
    });
    this.$emitter.on('header_reset_filters', () => {
      if (this.gridApi && this.gridApi.destroyCalled === false) {
        this.gridApi.setFilterModel(null);
        this.gridApi.setGridOption("quickFilterText", null);
        this.selectedFilters = ['SEO', 'PPC', 'DEV', 'MNT'];
        this.$nextTick(() => {
          this.applyTypeFilter();
        });
        const timeout = setTimeout(() => {
          this.$store.dispatch('setAgGridFilterChanged', { page: this.$route.name, query: '' });
          clearTimeout(timeout);
        }, 10);
      }
    });
  },
  mounted() {
    (async() => {
      this.setViewLoader(true);
      const [records, projects, editors, selectors] = await Promise.all([
        MtApi.getContentRecords(),
        MtApi.getContentProjects(),
        MtApi.getContentEditors(),
        MtApi.getContentSelectors(),
      ])
      
      this.records = records
      this.projects = projects
      this.editors = editors
      this.selectors = selectors
      this.columnDefs = Object.freeze(
        this.generateColumnDefs({ projects, selectors, editors })
      );
      if (records && records.length > 0) {
        this.rowData = Object.freeze(
          this.copyRowData(records)
        );
      } else {
        this.rowData = [];
      }
      this.$nextTick(() => {
        this.gridApi.setGridOption("quickFilterText", this.$store.getters.getSearchQuery(this.$route.name));
        this.setState();
        this.applyTypeFilter();
        this.setViewLoader(false);
      });
    })();
  }
}
</script>

<style lang="scss">
.custom-control-label {
  display: flex;
  align-items: center
}
</style>