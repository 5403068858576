import { DateRangeEditor } from './dateRangeEditor'

export function getColumnDefs({ projects, selectors, editors }) {
	const editorsValues = []
	const editorsKeyToValue = {}
	console.log(editors)
	if (editors && editors.length > 0) {
		for (const editor of editors) {
			editorsValues.push(editor['_id'])
			editorsKeyToValue[editor['_id']] = editor['name']
		}
	}

	return [
		{
			field: 'id',
			hide: true,
			sort: "desc"
		},
		{
			headerName: 'Project',
			field: 'project',
			editable: true,
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: {
				values: projects,
			},
			valueGetter: (params) => {
				return params.data.project ?? null
			},
			valueFormatter: (params) => {
				if (!params.value) {
					return 'Choose Project'
				}
				const { platform, abbr, type } = params.value
				if (!abbr && !type) {
					return 'Choose Project'
				}
				return (platform ? platform.url : '...') + ` (${abbr} ${type})`
			},
			valueSetter: (params) => {
				let value
				if (params.newValue === '' || params.newValue === null) {
					return false
				}
				if (typeof params.newValue === 'string') {
					value = JSON.parse(params.newValue)
				} else {
					value = params.newValue
				}
				params.data.project = value
				const { pm } = params.data.project
				params.data.projectManager = { _id: pm['_id'], name: pm.name }
				return true
			},

			// filter: 'agSetColumnFilter',
			// filterParams: {
			// 	comparator: (filterValue, cellValue) => {
			// 		return cellValue == filterValue;
			// 	},
			// 	values: projects,
			// 	cellRenderer: (params) => {
			// 		if (!params.value) return '(Blanks)'
			// 		if (!params.value.abbr || !params.value.type) return params.value
			// 		return `${params.value.abbr}-${params.value.type}`
			// 	}
			// }
		},
		{
			headerName: 'Category',
			field: 'category',
			editable: true,
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: {
				values: selectors.category,
			},
			cellRenderer: (params) => params.value ?? selectors.category[0],
		},
		{
			headerName: 'Task Title',
			field: 'taskTitle',
			editable: true,
			cellEditor: 'agTextCellEditor',
		},
		{
			headerName: 'Content Type',
			field: 'contentType',
			editable: true,
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: { values: selectors.contentType },
			cellRenderer: (params) =>
				params.value ?? selectors.contentType[0],
		},
		{
			headerName: 'Priority',
			field: 'priority',
			editable: true,
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: { values: selectors.priority },
			cellRenderer: (params) => params.value ?? selectors.priority[0],
		},
		{
			headerName: 'Deadline',
			field: 'deadline',
			editable: true,
			cellEditor: 'agDateCellEditor',
			valueGetter: (params) => {
				return new Date(params.data.deadline) || null
			},
			valueFormatter: (params) => {
				return params.value
					? new Date(params.value).toLocaleDateString('en-us')
					: 'dd/mm/yyyy'
			},
		},
		{
			headerName: 'Status from Hanna',
			field: 'statusHanna',
			editable: true,
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: { values: selectors.statusHanna },
			cellRenderer: (params) =>
				params.value ?? selectors.statusHanna[0],
		},
		{
			headerName: 'Editor',
			field: 'editor',
			editable: true,
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: { values: editorsValues },
			valueFormatter: (params) => {
				if (!params.value) return 'Choose Editor'
				return editorsKeyToValue[params.value]
			},
		},
		{
			headerName: 'Spec Sheet/Content Spec',
			field: 'specLink',
			editable: true,
			cellEditor: 'agTextCellEditor',
			cellRenderer: (params) => {
				if (!params.value) return 'Input Link'
				return createLink(params.value)
			},
		},
		{
			headerName: 'Final Content',
			field: 'finalContent',
			editable: true,
			cellEditor: 'agTextCellEditor',
			cellRenderer: (params) => {
				if (!params.value) return 'Input Link'
				return createLink(params.value)
			},
		},
		{
			headerName: 'Page Link',
			field: 'pageLink',
			editable: true,
			cellEditor: 'agTextCellEditor',
			cellRenderer: (params) => {
				if (!params.value) return 'Input Link'
				return createLink(params.value)
			},
		},
		{
			headerName: 'Words',
			field: 'words',
			editable: false,
			cellEditor: 'agNumberCellEditor',
			valueFormatter: (p) => p.value ?? 0,
		},
		{
			headerName: 'Uniqueness',
			field: 'uniqueness',
			editable: false,
			cellEditor: 'agNumberCellEditor',
			cellEditorParams: {
				min: 0,
				max: 100,
			},
			valueFormatter: (p) => +(p.value ?? 0) + '%',
		},
		{
			headerName: 'Pack',
			field: 'pack',
			editable: true,
			cellEditor: DateRangeEditor,
			valueGetter: (params) => {
				return params.data.pack
			},
			valueSetter: (params) => {
				let value
				if (typeof params.newValue === 'string') {
					value = JSON.parse(params.newValue)
				} else value = params.newValue
				params.data.pack = value
				return true
			},
			valueFormatter: (p) => {
				const startDate =
					p.value && p.value.packStart ? p.value.packStart : 'yyyy - mm'
				const endDate =
					p.value && p.value.packEnd ? p.value.packEnd : 'yyyy - mm'
				return startDate + ' / ' + endDate
			},
		},
		{
			headerName: 'PM',
			field: 'projectManager',
			editable: false,
			cellEditor: 'agTextCellEditor',
			valueFormatter: (params) => {
				return params.value ? params.value.name : 'not found'
			},
		}, // change to automatic get
		{
			headerName: 'Status for PM',
			field: 'statusPM',
			editable: true,
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: { values: selectors.statusPM },
			cellRenderer: (params) => params.value ?? selectors.statusPM[0],
		},
		{
			headerName: 'Issues/Comments',
			field: 'comments',
			editable: true,
			cellEditor: 'agLargeTextCellEditor',
			cellEditorPopup: true,
		},
	]
}

function createLink(href) {
	const res = href.match(/(https?:\/\/[^\s]+)/g)
	if (res === null) return 'Invalid URL'
	return `<a href=${href} target="_blank" class="cell_link">${
		href
	}</a>`
}
