export class DateRangeEditor {
	eGui
	params
	eInputStartDate
	eInputEndDate
	endMonth
	endYear

	init(params) {
		const currentDay = new Date()

		this.params = params
		const { packStart, packEnd } = params.value
		this.eInputStartDate = document.createElement('input')
		this.eInputStartDate.type = 'month'
		this.eInputStartDate.lang = 'en'
		this.eInputStartDate.value =
			packStart ?? currentDay.getFullYear() + '-' + this.getMonth(currentDay)

		currentDay.setMonth(currentDay.getMonth() + 1)

		this.eInputEndDate = document.createElement('input')
		this.eInputEndDate.type = 'month'
		this.eInputEndDate.lang = 'en'
		this.eInputEndDate.value =
			packEnd ?? currentDay.getFullYear() + '-' + this.getMonth(currentDay)

		this.eInputStartDate.addEventListener('change', this.updateRange.bind(this))
	}

	getMonth(date) {
		const month = date.getMonth() + 1 + ''
		return month.length > 1 ? month : '0' + month
	}

	updateRange() {
		const startDate = new Date(this.eInputStartDate.value)
		startDate.setMonth(startDate.getMonth() + 1)
		const nextMonth = this.getMonth(startDate)
		const endDate = startDate.getFullYear() + '-' + nextMonth
		this.eInputEndDate.value = endDate

		this.params.stopEditing()
	}

	getGui() {
		const eDiv = document.createElement('div')
		eDiv.appendChild(this.eInputStartDate)
		return eDiv
	}

	afterGuiAttached() {
		this.eInputStartDate.focus()
	}

	getValue() {
		return {
			packStart: this.eInputStartDate.value,
			packEnd: this.eInputEndDate.value,
		}
	}

	destroy() {
		this.eInputStartDate.removeEventListener(
			'change',
			this.updateRange.bind(this)
		)
	}
}
